import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client/core'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import CurrentI18n from "@common/utils/CurrentI18n";

const httpOptions = {
    // You should use an absolute URL here
    uri: `https://${window.location.hostname}/api/graphql`,
    credentials: 'same-origin'
}
// HTTP connection to the API
const httpLink = createUploadLink(httpOptions)

// Cache implementation
const cache = new InMemoryCache({
    addTypename: true
})


const authLink = new ApolloLink((operation, forward) => {
    const schoolUUID = window.__passdata.school.uuid

    const { locale } = CurrentI18n.getInstance().global;
    const lang = locale.value

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            'School-Id': schoolUUID,
            'Apollo-Require-Preflight': 'true',
            'Locale': lang
        }
    }));

    return forward(operation);
})
// Create the apollo client
const client = new ApolloClient({
    cache,
    link: from([authLink, httpLink]),
    connectToDevTools: true,
})

export default client
